
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class infomationAlert extends Vue {
  @Prop({
    type: Boolean,
    required: true
  })
  private display!: boolean

  @Prop({
    type: String,
    required: false
  })
  private text!: string

  @Prop({
    type: String,
    required: true
  })
  private classname!: string

  @Prop({
    type: Boolean,
    required: false
  })
  private block!: boolean

  @Prop({
    type: Boolean,
    default: false,
    required: false
  })
  private loginError: boolean

  private links = require('~/assets/json/links.json')

  private errorText: string = `入力された内容でログインできませんでした。\n内容をご確認の上、再度お試しください。<span class="spnone">\n企業担当者は<a href="${this.links.cmsLogin}" class="annotation-text-link" target="_blank">こちら</a>からログインしてください。</span>`
}
